<template>
  <div class="inquiry">
      <div class="info">
        <h1>库存管理</h1>
        <a-row class="list">
            <a-col :span="7">
                <div class="single">
                    <label>仓库</label>
                    <a-input placeholder="请输入" />
                </div>
            </a-col>
            <a-col :span="7">
                 <div class="single">
                    <label>物品</label>
                    <a-input placeholder="请输入" />
                </div>
            </a-col>
            <a-col :span="7">
                 <div class="single">
                    <label>类型</label>
                    <a-select>
                        <a-select-option value="类型1">类型1</a-select-option>
                        <a-select-option value="类型2">类型2</a-select-option>
                        <a-select-option value="类型3">类型3</a-select-option>
                    </a-select>
                </div>
            </a-col>
            <a-col :span="3">
                <div class="single">
                    <a-button type="primary">查询</a-button>
                </div>
            </a-col>
        </a-row>
        <div class="href-btn">
            <a-button>切换为订单视图</a-button>
        </div>
        <a-table :columns="columns" :data-source="data" :pagination="pagination">
          <span slot="action">
            <a @click="goDetail">查看详情</a>
          </span>
        </a-table>
      </div>
  </div>
</template>

<script>
const columns = [
  {
    title: '物品名称',
    dataIndex: 'itemName',
  },
  {
    title: '物品编码',
    dataIndex: 'itemCode',
  },
  {
    title: '版本',
    dataIndex: 'version',
  },
  {
    title: '类型',
    dataIndex: 'itemType',
  },
  {
    title: '总库存',
    dataIndex: 'AllStock',
  },
  {
    title: '可用库存',
    dataIndex: 'useStock',
  },
  {
    title: '库存单位',
    dataIndex: 'stockUnit',
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
];

const data = [
  {
    key: '1',
    itemName:'GT300型号GPS导航仪',
    itemCode:'#DFD#',
    version:'V1.0',
    itemType:'产品',
    AllStock:'2000',
    useStock:'1000',
    stockUnit:'个',
  },
];
export default {
  data() {
    return {
      data,
      columns,
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
    };
  },
  methods: {
  // 跳转新增入库单
      goDetail() {
        this.$router.push({ path: `/Storage/InventoryDistributed` });
      },
    },
};
</script>

<style lang="scss" scoped>
  .inquiry{
    padding: 30px;

    .info{
      background-color: #ffffff;
      padding: 30px;

      h1{
        font-size: 18px;
        font-weight: bold;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 10px;
      }

      .list{
          margin: 35px 0;

          .single{

            label{
                display: inline-block;
                margin-right: 15px;
            }

            .ant-select{
                width: 80%;
            }
            .ant-input{
                width: 80%;
            }
            
          }
      }
      .href-btn{
          text-align: right;
          margin-bottom: 20px;
      }
    }
  }
</style>
